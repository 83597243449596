
  .battery-body {
    width: 24px;
    height: 12px;
    background-color: #ddd;
    position: relative;
  }

  .battery-tip {
    width: 4px;
    height: 6px;
    background-color: #ddd;
    position: relative;
    top: 3px;
    right: -24px;
  }

  .battery-level {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

/* Green battery color */
.green {
  background-color: green;
}

/* Yellow battery color */
.yellow {
  background-color: yellow;
}

/* Red battery color */
.red {
  background-color: red;
}