.circle {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.red-circle {
  background-color: red;
}

.yellow-circle {
  background-color: yellow;
}

.green-circle {
  background-color: green;
}

.empty-circle {
  width: 10.2px;
  height: 10.2px;
  border: 1px solid #000;
}